import { Button, Divider } from 'antd';
import Link from 'components/Link';
import OrderProgress from 'components/OrderProgress';
import { OrderSummaryOrderConfirmation as OrderSummary } from '../../../../embeddedCart/embeddedOrderSummary';
import PoweredByGrowFlow from 'components/PoweredByGrowFlow';
import React from 'react';
import { checkoutContainer } from '../../../../embeddedCart/embeddedOrder/styles';
import { orderCompleteContainer } from './styles';
import { uris } from 'router';

const { storeUri } = uris;

const OrderComplete = ({
  orderNumber = 0,
  name = '',
  currentStoreDisplayArgs = {},
} = {}) => (
  <div className={checkoutContainer}>
    <OrderProgress steps={3} currentStep={3} />
    <div className={orderCompleteContainer}>
      <h2>{name}</h2>

      <h1>Order Complete!</h1>

      <p className="regular-plus">
        Thanks for your order! We'll get to work on it right away.
      </p>

      <OrderSummary orderNumber={orderNumber} />

      <div className="button-container">
        <Link to={storeUri(currentStoreDisplayArgs)}>
          <Button>RETURN TO STORE</Button>
        </Link>
      </div>
    </div>
    <Divider />
    <PoweredByGrowFlow />
  </div>
);

export default OrderComplete;
