import { createViewModelAttributeBuilder, createViewModelMap } from 'utils/viewModel';
import { storeFront, storeFrontId } from '../shared/viewModel';
import { fileAbsolute } from 'paths.macro';
import { accessors as storeAccessors } from '../viewModel';

const viewModelAttribute = createViewModelAttributeBuilder(fileAbsolute);

const orderComplete = viewModelAttribute(
  val => val,
  val => val
);

const orderNumber = viewModelAttribute(orderComplete, ({ orderNumber }) => orderNumber);

const accessors = {
  ...storeAccessors,
  imageUrl: state => storeFront(state).logoUrl,
  name: state => storeFront(state).name,
  storeFrontId,
  orderNumber,
};

const builder = createViewModelMap(accessors);

export const createViewModel = (state, ownProps = {}) =>
  builder({ ...state, ...ownProps });
