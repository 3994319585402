import { pageWithAuth, withSEO } from 'utils/views';
import Template from './template';
import { createViewModel } from '../../../viewModel';
import { Main as withLayout } from 'layouts';

export default withSEO({
  title: 'Order Complete',
})(
  pageWithAuth({
    Template,
    withLayout,
    createViewModel,
  })
);
