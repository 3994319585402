import EmbeddedOrder from '../store/embeddedCart/embeddedOrder';
import OrderComplete from '../store/order-complete';
import React from 'react';
import { RetailCart } from '../store/embeddedCart';
import RetailStore from './';
import { Router } from '@reach/router';
import { withPrefix } from 'router';

const App = () => (
  <Router basepath={withPrefix('/retailMenu/:clientId/:menuId')}>
    <RetailStore path="/" />
    <RetailCart path="/cart" />
    <EmbeddedOrder path="/cart/customerInfo" />
    <OrderComplete path="/orderComplete/:orderNumber" />
  </Router>
);

export default App;
