import { clearOrderSummary } from 'routes/store/embeddedCart/embeddedOrderSummary/controller.orderComplete';

export const createTasks = () => {
  const init = () => {
    clearOrderSummary();
  };

  return {
    init,
  };
};
