import { css } from 'emotion';

export const orderCompleteContainer = css`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;

  h1,
  p.regular-plus {
    text-align: center;
    margin-bottom: 18px;
    color: var(--gray-700);
  }

  h1 {
    margin-top: 40px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto 0;
    > * + * {
      margin-left: 12px;
    }
  }

  @media (min-width: 768px) {
    margin: 34px auto 0;
  }
`;
